// header
.header {
	z-index: 998;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	background: transparent;
	transition: 0.5s;

	.container,
	.container-fluid {
		.container-holder {
			>.column {
				align-items: center;

				@include media-breakpoint-down(lg) {
					flex-flow: row wrap;
				}

				@extend .navbar-expand-xl;
			}
		}
	}

	// header-top
	.header-top {
		background: $section-bg;

		.container-holder {
			.column.default {
				flex-wrap: nowrap;
			}
		}
	}

	// header-main 
	.header-main { 
		background-color: transparent;
		margin-top: 1.5rem;
		transition: .5s;
	}

	// logo
	.logo {
		transition: .5s;
		width: 200px;
		margin: 0 30px 0 0;

		@include media-breakpoint-down(xs) {
			width: 120px
		}

		a {
			@extend .d-block;

			img {
				@extend .w-100;
			}
		}
	}

	// menu
	.menu {
		// top menu
		&#top_menu_menu {
			margin-left: auto;
			display: flex !important;
			justify-content: flex-end;
			margin-right: 10px;
			.navbar-nav {
				flex-direction: row;
				.nav-item {
					.nav-link {
						color: $primary;
						padding: 10px;
						
						@include media-breakpoint-down(xs) {
							padding: 10px 5px;
							font-size: 12px;
						}
	
						&:hover {
							color: $secondary;
						}
					}
	
					&.active {
						.nav-link {
							color: $black;
							font-weight: bold;
							&:hover {
								color: $secondary;
							}
						}
					}
				}
			}
		}

		// main menu
		&#default_menu {
			@include media-breakpoint-down(xs) {
				order: 1;
			}
			.navbar-nav {
				.nav-item {
					> .nav-link {
						color: $white;
						font-size: 18px;
						font-weight: 600;

						&:hover {
							> .nav-link {
								color: $secondary;
							}
						}
					}

					&.active {
						> .nav-link {
							color: $primary;
							font-weight: bold;
							&:hover {
								> .nav-link {
									color: $secondary;
								}
							}
						}
					}

					.dropdown-menu {
						border: 1px solid $white;
						.nav-item {
							.nav-link {
								color: $primary;
								font-weight: 600;
								padding-left: .5rem;
								padding-right: .5rem;
								&:hover {
									color: $secondary;
								}
							}
						}
					}
				}
			}
			@include media-breakpoint-down(lg) {
				.navbar-nav {
					.nav-item {
						> .nav-link {
							font-size: 14px;
						}
					}
				}
			}
	
			@include media-breakpoint-up(xl) {}
		}
	}

	// site-switcher
	.site-switcher {
		.language {
			flex-wrap: nowrap !important;
			.nav-item {
				.nav-link {
					border: none;
					border-radius: unset;
					color: $primary;
					padding: 10px 5px !important;

					@include media-breakpoint-down(xs) {
						font-size: 12px;
					}
					
					&:hover {
						box-shadow: none !important;
						color: $secondary;
					}
				}

				&.active {
					.nav-link {
						color: $black;
						font-weight: bold;
						&:hover {
							color: $secondary;
						}
					}
				}

				+ li {
					margin-left: 0;
				}
			}
		}
	}


	// menu-toggle
	.menu-toggle {
		margin-left: auto;

		.navbar-toggler {
			color: $white;
			background: $blue-dark;
			padding: 17px 10px;
			display: flex;
			align-items: center;
			border: none;
			border-radius: 3px 0 0 3px;
			transition: .5s;
			&-icon {
				font-size: 18px;
				margin-right: 5px;
			}

			&-label {
				font-weight: 700;
				font-size: 1rem;
			}

			@include media-breakpoint-down(xs) {
				padding: 11px 8px;
				&-label {
					font-size: 10px;
				}
			}
		}
	}


	// Zoek en boek button
	div.book-button {
		ul.book-button {
			li {
				a {
					padding: 10px 1.5rem;
					color: $white;
					text-decoration: none;
					background: $blue;
					border-radius: 3px;
					font-weight: bold;
					display: flex;
					align-items: center;
					&:hover {
						background: $blue-dark;
					}

					&::after {
						content: '\f002';
						font-family: 'Font Awesome 5 Pro';
						--icon-size: 24px;
						font-size: var(--icon-size);
						width: var(--icon-size);
						height: var(--icon-size);
						line-height: var(--icon-size);
						flex: 0 0 var(--icon-size);
						margin-left: 6px;
						font-weight: 400;
					}

					@include media-breakpoint-down(lg) {
						flex-direction: row-reverse;
						line-height: 1rem;
						padding: 10px;
						border-radius: 0 3px 3px 0;
						&::after {
							margin-left: 0;
							margin-right: 6px;
						}

						.list-item-title {
							max-width: 74px;
						}
					}

					@include media-breakpoint-down(xs) {
						padding: 8px;
						font-size: 10px;
						line-height: 11px;
						&::after {
							font-size: 18px;
						}

						.list-item-title {
							max-width: 48px;
						}
					}
				}
			}
		}
	}

	&.sticky {
		// display: none !important;
		box-shadow: $shadow;
		background: $primary;
		margin-top: -38px;
		@include media-breakpoint-up(sm) {
			margin-top: -44px;
		}

		// main-header
		.header-main {
			margin-top: 10px;
			margin-bottom: 10px;
		}

		// main menu
		.menu {
			&#default_menu {
				.navbar-nav {
					.nav-item {
						> .nav-link {
							color: $white;
						}
						&.active,
						&:hover {
							> .nav-link {
								color: $secondary;
							}
						}
					}
				}
			}
		}

		.logo {
			@include media-breakpoint-up(sm) {
				width: 120px;
			}
		}

		.logo {
			@include media-breakpoint-up(lg) {
				width: 100px;
			}
		}

		div.book-button {
			ul.book-button {
				li {
					a {
						@include media-breakpoint-up(xl) {
							padding: .5rem 1rem;
						}
					}
				}
			}
		}
	}
}

&.location-overview {
	padding-top: 0;
	.header {
		position: relative;
		background-color: $primary;

		.header-main {
			margin-top: 0;
			padding-top: 1.5rem;
			padding-bottom: 1.5rem;
		}

		.menu {
			// main menu
			&#default_menu {
				.navbar-nav {
					.nav-item {
						&:hover,
						&.active {
							> .nav-link {
								color: $secondary;
							}
						}
					}
				}
			}
		}
	}
}