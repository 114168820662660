&.location-detail {
    .lead-section {
        .info {
            .main-info {
                .wysiwyg {
                    p {
                        margin-bottom: 0
                    }
                }
            }
        }
    }
    .content-section {
        .location-description {
            @include media-breakpoint-up(lg) {
                @include make-col(11);
            }
            flex-shrink: 1;
            font-size: 18px;
            margin: 1.5rem 0;
        }
    }
}