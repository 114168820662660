// bg-*
// =========================================================================
// section {

// 	&.bg-light,
// 	&.bg-dark {
// 		margin: 0;
// 		padding: 3rem 0;
// 	}
// }

.lead-section+.content-section,
.lead-section+.bundle-overview-section {
	margin-top: calc(-6vh + 30px);
}

// intro-section
// =========================================================================
.intro-section {
	position: relative;
	z-index: 10;

	.go-to-section {
		margin-left: auto;
		margin-right: auto;
	}
}

// lead-section
// =========================================================================
.lead-section {
	padding: 3rem 0 1rem;

	// bundles
	.info-nav {
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .align-items-center;
		@extend .justify-content-between;
		margin-bottom: 30px;
	}

	.info {
		// accommodation
		.properties {
			@extend .d-flex;
			@extend .flex-wrap;
			@extend .justify-content-start;
			@extend .list-unstyled;

			li {
				margin: .5rem 1rem;
				&:first-child {
					margin-left: 0;
				}
			}

			.icon {
				margin-right: 5px;
			}
		}

		// assortiment
		.former-price {
			font-size: $font-size-sm;
			text-decoration: line-through;
		}

		.discount-price {
			font-size: $font-size-sm;

			.amount {
				font-size: $font-size-lg;
			}

			margin-bottom: 1rem;
		}

		.price {
			margin-bottom: 1rem;

			.amount {
				font-size: $font-size-lg;
			}
		}

	}
}

// content-section
// =========================================================================
.content-section {
	padding: 3rem 0;
}

&.service-detail {
	.content-section {
		@extend .bg-light;
	}
}

// block-section
// =========================================================================
.block-section {
	padding: 3rem 0;
}

// collection-section
// =========================================================================
.collection-section {
	padding: 3rem 0;

	&#overnachten {
		padding-bottom: 5rem;
	}
}

// banner-section
// =========================================================================
// .banner-section {}

// bundle-overview-section
// =========================================================================
.bundle-overview-section {
	padding: 6vh 0;

	// collection
	.collection {
		.card {
			@extend .default-card !optional;
		}
	}
}

// bundle-detail-section
// =========================================================================
.bundle-detail-section {
	margin: 2rem 0;
}

// gallery-section
// =========================================================================
.gallery-section {
	@include media-breakpoint-up(sm) {
		margin-top: -1rem;
	}
	padding-bottom: 3rem;
	// margin-bottom: calc(-6vh + 30px);
}

// booking-section
// =========================================================================
.booking-section {
	margin: 6vh 0;

	h2 {
		margin-bottom: 30px;
	}
}

// contact-section
// =========================================================================
.contact-section {
	margin: 6vh 0;
}

// newsletter-section
// =========================================================================
.newsletter-section {

	.container-holder {
		@include media-breakpoint-only(xs) {
			padding: 0 1rem;
		}
	}

	.bg-dark {
		border-radius: 20px;
	}
}

// arrangement-section
// =========================================================================
.arrangement-section {
	margin-top: 4rem;
	.container-holder {
		@include media-breakpoint-only(xs) {
			padding: 0 1rem;
		}
	}

	.bg-dark {
		border-radius: 20px;
		padding-top: 44px;
		padding-bottom: 50px;

		> .page-block {
			width: 100%;
			max-width: 650px;
			margin: auto;

			@include media-breakpoint-down(md) {
				max-width: 500px;
			}
	
			&.park-arrangement-description * {
				color: $white !important;
			}
	
			&.arrangement-link {
				a {
					@extend .btn;
					@extend .btn-primary;
					@extend .btn-link;
					padding-left: 30px !important;
					padding-right: 18px !important;
					position: relative;
	
					&::after {
						content: '\f1d8' !important;
					}

					&::before {
						content: url("/images/book-man-big.svg");
						display: inline-block;
						position: absolute;
						left: -100px;
						bottom: -106px;
						@include media-breakpoint-down(xs) {
							left: 50%;
							top: -66px;
							width: 52px;
							height: 78px;
							content: '';
							background-image: url("/images/book-man-big.svg");
							background-size: contain;
							background-repeat: no-repeat;
							@include prefix(transform, translateX(-50%));

						}
					}
					@include media-breakpoint-down(xs) {
						margin-top: 60px;
					}
				}
			}
		}
	}

}

// nav-section
// =========================================================================
.nav-section {
	padding: 1.5rem 0;
	position: relative;
	background-color: $body-bg;
	z-index: 99;

	.container-two-columns {
		.container-holder {
			> .column {
				justify-content: center;

				&.one {
					padding-right: 15px !important;
					@include make-col(12);
					@include media-breakpoint-up(lg) {
						@include make-col(7)
					}
					@include media-breakpoint-down(xs) {
						margin-bottom: -1rem;
					}
				}
				&.two {
					padding-left: 15px !important;
					flex-direction: row;
					justify-content: flex-end;
					@include make-col(12);
					@include media-breakpoint-up(lg) {
						@include make-col(5)
					}
		
					@include media-breakpoint-down(md) {
						order: -1;
						margin-bottom: 30px;
					}
				}
			}
		}
	}

	&.sticky-navbar {
		width: 100%;
		box-shadow: $shadow;
	}
}

#overnachten,
#verhalen {
	overflow-x: hidden;
}