.newsletter-form {
    border: none;
    padding: 44px 0 50px;
    max-width: 650px;
    margin: auto;

    h2 {
        color: $white !important;
        font-weight: 400;

        + p {
            font-size: 18px;
        }
    }

    .recaptcha {
        // display: none;
    }

    #form_3 {
        display: flex;
        align-items: flex-end;
        flex-flow: row wrap;

        .alert {
            flex: 1 1 100%;
        }

        .form-group {
            &:not(:last-child) {
                flex: 1 1 auto;
                margin-right: 1rem;
            }
            .btn,
            .form-control {
                border-radius: 3px;
                height: 48px;
            }

            .form-control {
                border: none;
            }

            .btn {
                box-shadow: none;
                padding: 10px 30px;
                &:hover {
                    background-color: $secondary;
                    border-color: $secondary;
                }

                &::after {
                    content: '\f1d8';
                    font-family: $font-awesome;
                    font-size: 1.5rem; // 24px
                    width: 1.5rem;
                    height: 1.5rem;
                    line-height: 1.5rem;
                    display: inline-block;
                    font-weight: 300;
                    vertical-align: middle;
                    margin-left: 20px;
                }
            }
        }
    }

    //reset style !!
    .newsletter-form {
        padding: 0;
    }
}