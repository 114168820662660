// eyecatcher
.eyecatcher,
.eyecatcher .owl-carousel .item {
	height: 50vh;
	min-height: 300px;
	max-height: 540px;
}

.eyecatcher.large,
.eyecatcher.large .owl-carousel .item {
	height: 700px;
	min-height: 540px;
	max-height: 1080px;
	position: relative;

	// @include media-breakpoint-up(sm) {
	// 	height: 100vh;
	// }
}

.eyecatcher.large {
	&::before {
		content: '';
		position: absolute;
		display: block;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background: linear-gradient(rgba($black, .7) 15%, rgba($white, 0) 50%);
		z-index: 10;
	}
}