div.location-nav {
    &::after,
    &::before {
        content: '';
        pointer-events: none;
        position: absolute;
        top: 0;
        height: 43px;
        width: 50px;
        opacity: 0;
        z-index: 1;
    }

    &::after {
        right: 0;
        background-image: linear-gradient(90deg, rgba($white, 0), rgba($body-bg, 1));
    }

    &::before {
        left: 0;
        background-image: linear-gradient(-90deg, rgba($white, 0), rgba($body-bg, 1));
    }

    &.scrollable {
        &.to-left {
            &::after {
                opacity: 1;
            }
        }
        &.to-right {
            &::before {
                opacity: 1;
            }
        }
    }
}

ul.location-nav {
    flex-wrap: nowrap !important;
    word-break: keep-all;
    white-space: nowrap;
    position: relative;
    @include media-breakpoint-down(xs) {
        overflow: scroll;
        padding: 5px 0 15px;
        margin-bottom: -.5rem;
        &::-webkit-scrollbar {
            width: 0;
        }
    }

    li {
        margin-right: 20px;
        
        a {
            position: relative;
            text-decoration: none;
            padding: .5rem 0;
            color: $black;
            font-weight: 400;
            font-size: 12px;
            @include media-breakpoint-up(sm) {
                font-weight: 600;
				font-size: 16px;
			}
        }
        &.active {
            a {
                color: $link-color;
                &::after {
                    content: '';
                    display: block;
                    width: 100%;
                    height: 5px;
                    background-color: $link-color;
                    position: absolute;
                    bottom: -10px;
                    @include media-breakpoint-up(sm) {
                        bottom: -20px;
                    }
                    @include media-breakpoint-up(lg) {
                        bottom: -27px;
                    }
                }
            }

        }
    }
}