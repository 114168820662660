.facilities,
.surrounding {
    @include media-breakpoint-down(xs) {
        .row {
            display: none;
        }

        h2 {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 1rem;
            &::after {
                content: '\f078';
                font-family: $font-awesome;
            }

            &.opend {
                &::after {
                    content: '\f077';
                    font-family: $font-awesome;
                }
            }
        }
    }
}