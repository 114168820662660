// footer
.footer {
	.footer-outro {
		position: relative;
		padding-top: 100px;

		.footer-logo {
			position: absolute;
			top: 100%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}

	.footer-sitelinks {
		background: $footer-bg;
		padding: 100px 0 60px;
		@include media-breakpoint-up(md) {
			padding: 100px 0;
		}

		text-align: center;

		.footer-text {
			max-width: 700px;
			margin: auto;

			h1,
			h2,
			h3,
			h4,
			h5,
			h6 {
				font-weight: 400;
				color: $white;
			}

			h1 {
				margin-bottom: 5px;
				font-size: 2.25rem;
				@include media-breakpoint-up(sm) {
					font-size: $h1-font-size;
				}
			}

			h3 {
				margin-bottom: 1rem;
				font-size: $h3-font-size;
			}

			p {
				color: $white;
			}
		}

		// social media icons
		ul.vocabulary-term-list {
			justify-content: center;
			align-items: center;
			margin: 2rem 0;

			li {
				&:not(:last-child) {
					margin-right: 3rem;
				}
				a {
					display: block;
					width: 3rem;
					height: 3rem;
					line-height: 3rem;
					font-size: 3rem;
					color: $white;

					@include media-breakpoint-up(sm) {
						font-size: 3.75rem; // 60px
						width: 3.75rem;
						height: 3.75rem;
						line-height: 3.75rem;
					}

					&:hover {
						color: darken($blue-dark, 20%);
					}

					.list-item-title {
						display: none;
					}
				}
			}
		}

		// footer logo links
		.footer-logolink {
			margin: 2rem 0 0;
			.list {
				justify-content: center;
				align-items: center;
				.list-item {
					flex: 1 1 100%;
					max-width: 280px;
					&:not(:last-child) {
						margin-bottom: 1rem;
						@include media-breakpoint-up(md) {
							margin-right: 1rem;
							margin-bottom: 0;
						}
					}
					.link {
						padding: 1.25rem 1.5rem;
						font-size: 1.5rem; // 24px
						color: $white;
						background-color: $blue;
						display: flex;
						border-radius: 3px;
						text-decoration: none;
						justify-content: center;

						&:hover {
							background-color: darken($blue-dark, 20%);
						}

						&::before {
							content: attr(title);
							margin-right: 1.5rem;
							font-size: 16px;
							font-weight: bold;
							vertical-align: middle;
						}
					}
				}
			}
		}
	}

	.footer-links {
		background: $footer-bg;
		padding: 3vh 0 9vh 0;
		.footer-list {
            margin-bottom: 3vh;
			h5 {
				color: $white;
				font-family: $font-family-primary;
				font-size: 21px;
                margin-top: 3vh;
			}
			a {
				text-decoration: none;
				color: $white;
				font-size: 14px;
				text-underline-offset: 5px;
				&:hover {
					color: $white;
					text-decoration: underline;
				}
			}
		}
	}

	.footer-copyright {
		background: $white;

		.footer-link {
			padding: 20px 0;

			.list {
				justify-content: center;
				@include media-breakpoint-down(xs) {
					flex-flow: column wrap;
					align-items: center;
				}
				.list-item {
					&:not(:last-child) {
						margin-bottom: 0.5rem;
						@include media-breakpoint-up(sm) {
							margin-right: 2rem;
							margin-bottom: 0;
						}
					}
					.link {
						text-decoration: none;
					}
				}
			}
		}
	}
}

&.service-category-detail,
&.bundle-detail,
&.home {
	.footer {
		.footer-outro {
			background: $white;
		}
	}
}
