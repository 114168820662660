// custom-list
.custom-list {
	padding: 0;
	list-style: none;

	li {
		position: relative;
		padding: 0 0 0 1.5rem;

		&::before {
			content: '\f00c';
			position: absolute;
			top: 0;
			left: 0;
			font-family: $font-awesome;
			color: $link-color;
			font-weight: 900;
		}
	}
}

// custom-list-extra
.custom-list-extra {
	padding: 0;
	list-style: none;

	li {
		position: relative;
		padding: 0 0 0 1.5rem;

		&::before {
			content: '\f058';
			position: absolute;
			top: 0;
			left: 0;
			font-family: $font-awesome;
			color: $link-color;
			font-weight: 400;
		}
	}
}

// intro-section
// =========================================================================
.intro-section {
	.custom-list-extra {
		display: flex;
		flex-flow: column wrap;
		justify-content: center;
		align-items: center;
		column-gap: 75px;
		row-gap: 10px;
		margin-bottom: 3.25rem;
		margin-top: 2rem;

		@include media-breakpoint-up(md) {
			flex-flow: row nowrap;
		}

		li {
			color: $link-color;
			padding: 0 0 0 1.875rem;
			font-weight: 600;
			&::before {
				font-size: 20px;
				line-height: 24px;
				font-weight: 300;
			}
		}
	}
}

// content-section
// =========================================================================
.content-section:not(#f-and-o) {
	.custom-list-extra {
		@include make-row();

		li {
			@include make-col-ready();

			@include media-breakpoint-up(md) {
				@include make-col(4);
			}

			&::before {
				position: relative;
				font-size: 24px;
				color: $blue;
			}

			h3 {
				color: $link-color;
			}
		}
	}
}

.content-section:is(#f-and-o) {
	.custom-list-extra {
		li {
			&::before {
				font-weight: 700;
				font-size: 12px;
				top: 3px;
			}
		}
	}
}