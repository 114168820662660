// mini-sab
&.home {
	.mini-sab {
		.container {
			// position: relative;
			@extend .d-flex;
			@extend .justify-content-center;
	
			.container-holder {
				@extend .w-100;
				z-index: 100;
				position: relative;
				margin-top: -250px;
				margin-bottom: -25px;
				background: $primary;
				box-shadow: 3px 3px 6px rgba($black, 0.15);
				color: #fff;
				padding: 7.25rem 1.25rem 2.875rem;
				border-radius: 14.75rem 14.75rem 0 0;
	
				// below eyecatcher
				@include media-breakpoint-up(md) {
					top: 200px;
					right: 15px;
					position: absolute;
					margin-top: 15px;
					padding: 3.5rem 1.5rem 3.5rem 8.75rem;
					max-width: 95%;
					border-radius: 14.75rem 0 0 14.75rem;
				}
	
				@include media-breakpoint-up(lg) {
					max-width: 870px;
				}
	
	
				// block image
				&::before {
					content: '';
					position: absolute;
					width: 100%;
					height: 100%;
					max-width: 246px;
					max-height: 246px;
					background-image: url("/images/home-mini-sab-mob.svg");
					background-size: contain;
					background-repeat: no-repeat;
					top: -156px;
					right: 57px;
	
					@include media-breakpoint-up(md) {
						background-image: url("/images/home-mini-sab.svg");
						max-width: 370px;
						max-height: 370px;
						left: -10px;
						bottom: -30px;
						top: auto;
						right: auto;
					}
				}
				
				h1,
				h2 {
					color: $white;
				}
	
				h2 {
					max-width: 290px;
					line-height: 30px;
				}
	
				.column {
					padding-left: 15px !important;
					padding-right: 15px !important;
				}
	
				// above eyecatcher
				/* @include media-breakpoint-up(lg) {
					position: absolute;
					left: auto;
					right: auto;
					bottom: 0;
				} */
			}
		}
	}
}

&:not(.home) {
	.mini-sab {
		.container {
			// position: relative;
			@extend .d-flex;
			@extend .justify-content-center;
	
			.container-holder {
				flex: 1;
				background: $primary;
				padding-bottom: 10px;
				border-radius: 5px;
				margin-top: -38px;
				z-index: 1;
				margin-left: 0;
				margin-right: 0;
				.filters {
					.filter-element.filter-context-button,
					.filter-element {
						@include media-breakpoint-up(md) {
							flex: 0 0 25%;
							max-width: 25%;
							&:not(:first-child) {
								padding-left: 15px;
							}
						}

						.widget-btn {
							height: 100%;
						}
						.widget-split-map-btn {
							margin-top: 0;
						}
					}
				}
			}

		}
	}
}