.collection {
    &.owl-carousel {
        &.slider {
            margin-top: 3.5rem;
            @include media-breakpoint-only(xs) {
                padding: 0 1rem;
            }
        }

        .owl-nav {
            position: absolute;
            top: -50px;
            width: 80px;
            height: 40px;
            right: 15px;
            
            .owl-prev,
            .owl-next {
                color: $link-color !important;
            }

            .owl-prev {
                left: 0;
            }

            .owl-next {
                right: 0;
            }
        }

        .owl-dots {
            .owl-dot {
                span {
                    border-color: $link-color !important;
                }
                &:hover,
                &.active {
                    span {
                        background: $link-color !important;
                    }
                }
            }
        }
    }

    &.no-desc {
        .card-description {
            display: none;
        }
    }

    &.no-tags {
        .card-tags {
            display: none;
        }
    }
}
