&.location-detail {

    .lead-section {
    
        .info {
            display: flex;
            flex-flow: row wrap;

            .main-info {
                order: 1;
                @include media-breakpoint-up(sm) {
                    @include make-col(8);
                } 
                @include media-breakpoint-up(lg) {
                    @include make-col(9);
                } 
            }
    
            .contact-info {
                order: 3;
                @include media-breakpoint-up(sm) {
                    order: 2;
                    @include make-col(4);
                    text-align: right;
                }
                @include media-breakpoint-up(lg) {
                    @include make-col(3);
                }
                
    
                // .address {}
                .contacts {
                    color: $link-color;
                    i {
                        vertical-align: middle;
                        font-size: 20px;
                        margin-right: 12px;
                    }
    
                    a {
                        color: $link-color !important;
                        &:hover {
                            color: $link-hover-color !important;
                        }
                    }
                }
            }
    
            .list {
                order: 2;
                flex: 0 0 100%;
                max-width: 100%;
                @include media-breakpoint-up(sm) {
                    order: 3;
                }
            }
    
        }
    }
}