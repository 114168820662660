.kenmerken-list {
    margin: 1rem 0 .5rem;
    .sub-list {
        @extend .list;
        display: flex;
        flex-flow: row wrap;

        .sub-list-item {
            padding: .43rem 1rem;
            border: 1px solid $quaternary;
            color: $quaternary;
            border-radius: 50px;
            font-size: 12px;
            line-height: normal;
            margin-bottom: .5rem;
            &:not(:last-child) {
                margin-right: .5rem;
            }
        }
    }
}

.tags {
    margin: 1rem 0 .5rem;
    .list {
        @extend .list;
        display: flex;
        flex-flow: row wrap;
        .list-item {
            padding: .43rem 1rem;
            border: 1px solid $quaternary;
            color: $quaternary;
            border-radius: 50px;
            font-size: 12px;
            line-height: normal;
            margin-bottom: .5rem;
            &:not(:last-child) {
                margin-right: .5rem;
            }

            .list-link {
                text-decoration: none;
            }
        }
    }
}