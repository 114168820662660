// body
// padding-top = header-top height


& {
	padding-top: 44px;
	@include media-breakpoint-down(xs) {
		padding-top: 38px;
	}
}


::-moz-selection { /* Code for Firefox */
	color: $white;
	background: $green;
}

::selection {
	color: $white;
	background: $green;
}

// container
.container {
	&.container-small {
		max-width: 540px;
	}

	&.container-medium {
		max-width: 720px;
	}

	&.container-intro {
		max-width: 720px;
		margin-bottom: 30px;
		text-align: center;
	}

	&.container-outro {
		max-width: 720px;
		margin-top: 30px;
		text-align: center;
	}
}

.container-fluid {
	padding-right: 38px;
	padding-left: 38px;
	@include media-breakpoint-down(sm) {
		padding-right: 24px;
		padding-left: 24px;
	}
}

// paragraph
p {
	min-height: 1rem;
}

// img & iframe
img {
	max-width: 100%;
	height: auto;
	object-fit: cover;
}

iframe {
	max-width: 100%;
}

// transition
a,
.btn,
.owl-btn,
.card-btn {
	transition: 0.5s;
}

// form
.form {
	padding: 15px;

	@include media-breakpoint-up(xl) {
		padding: 30px;
	}

	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}

// backgrounds
.bg-dark {
	background-color: $blue-dark !important;
}

.bg-light {
	background-color: $white !important;
}

// default-card
// .default-card {}

// reset google map image
.gm-style img {
	object-fit: contain;
}

.c-enormail-webform__panelholder {
	display: flex !important;
    justify-content: center !important;
}