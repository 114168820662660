// btn
.btn {
	box-shadow: $shadow;
	font-weight: 700;

	// btn-primary
	&.btn-primary {
		background: $tertiary;
		border-color: $tertiary;

		&:focus,
		&:active,
		&:hover {
			background: darken($quaternary, 20%) !important;
			border-color: darken($quaternary, 20%) !important;
		}
	}

	// btn-outline-primary
	&.btn-outline-primary {
		color: $tertiary;
		border-color: $tertiary;
		&:focus,
		&:active,
		&:hover {
			border-color: $quaternary;
			background-color: $quaternary;
			color: $white;
		}
	}

	// btn-secondary
	&.btn-secondary {
		background: $quaternary;
		border-color: $quaternary;
		color: $white;
		&:focus,
		&:active,
		&:hover {
			background: $tertiary !important;
			border-color: $tertiary !important;
		}
	}

	// btn-outline-secondary
	&.btn-outline-secondary {
		color: $quaternary;
		border-color: $quaternary;
		&:focus,
		&:active,
		&:hover {
			color: $white;
			border-color: $tertiary;
			background-color: $tertiary;
		}
	}

	&:focus {
		box-shadow: none !important;
	}
}

// card-btn
.card-btn {
	@extend .btn;
	@extend .btn-primary;
}

// btn-back
.btn-back {
	text-decoration: none;
	color: $link-color !important;
	i {
		margin-bottom: 0 !important;
		@include icon-size(1.5rem); // 24px
		padding: 7px;
		box-sizing: content-box;
		@include prefix(transform, rotate(-45deg));
		vertical-align: middle
	}
}

&.location-detail {
	.lead-section {
		position: relative;
		.container-one-column .container-holder > .column.default { position: static }
	}
	.btn-back {
		i {
			@include icon-size(2.25rem); // 36px
			position: absolute;
			top: -115px;
			z-index: 100;
			background-color: $body-bg;
			border-radius: 50%;
			left: 38px;
			@include media-breakpoint-down(md) {
				top: -165px;
			}
			@include media-breakpoint-down(sm) {
				left: 24px;
				top: -155px;
				@include icon-size(1.5rem); // 24px
				padding: 5px 4px 4px 5px;
			}
		}
	}
	
}

// btn-down
.btn-down {
	@extend .btn;
	@extend .btn-primary;
}

// btn-link
.btn-link {
	box-shadow: none;
	border: none;
	text-decoration: none !important;
	font-size: 18px;
	font-weight: 700;
	padding: 10px 0;
	display: inline-block;

	&::after {
		content: '\f0a9';
        font-family: $font-awesome;
        font-weight: 300;
		@include icon-size(1.5rem); // 24px
		vertical-align: middle;
		margin-left: 10px;
	}

	// btn-link-primary
	&.btn-link-primary {
		color: $tertiary !important;
		&:hover {
			color: $quaternary !important;
		}
	}

	// btn-link-secondary
	&.btn-link-secondary {
		color: $quaternary !important;
		&:hover {
			color: $tertiary !important;
		}
	}

}

// btn-location book
a.btn-location-book {
	@extend .btn;
	@extend .btn-primary;
	margin-right: 10px;
	position: relative;


	&::before {
		content: url("/images/book-man.svg");
		display: inline-block;
		position: absolute;
		left: -34px;
	}
}

// btn-location-visit
a.btn-location-visit {
	@extend .btn;
	@extend .btn-link;
	@extend .btn-secondary;
	color: $white !important;
}

a.btn-location-visit,
a.btn-location-book {
	display: flex;
	justify-content: center;
	align-items: center;
	line-height: normal;
	padding: .5rem 18px;
	font-size: 14px;
	height: 100%;

	@include media-breakpoint-up(sm) {
		font-size: 16px;
	}

	@include media-breakpoint-up(lg) {
		font-size: 18px;
	}
}