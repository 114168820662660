a.go-to-section {
    width: 50px;
    height: 50px;
    line-height: 50px;
    display: block;
    margin-top: -25px;
    background-color: $body-bg;
    border-radius: 50%;
    text-decoration: none;
    text-align: center;
    overflow: hidden;

    &::after {
        content: '\f0a9';
        font-family: $font-awesome;
        font-size: 2.25rem; // 36px
        font-weight: 300;
        transform: rotate(45deg);
        display: block;
        width: 100%;
        height: 100%;
    }
}