.gallery-section {
    .image-collection {
        .gallery-horizontal {
            .gallery-item.large-item {
                flex: 0 0 calc(100% - 260px);
                max-width: calc(100% - 260px);

                @include media-breakpoint-down(md) {
                    flex: 0 0 calc(100% - 200px);
                    max-width: calc(100% - 200px);
                }

                @include media-breakpoint-down(sm) {
                    flex: 0 0 100%;
                    max-width: 100%;
                }

                .gallery-link {
                    border-radius: 16px;
                }
            }

            .gallery-thumbs {
                flex: 0 0 260px;
                max-width: 260px;

                @include media-breakpoint-down(md) {
                    flex: 0 0 200px;
                    max-width: 200px;
                }

                @include media-breakpoint-down(sm) {
                    flex: 0 0 100%;
                    max-width: 100%;
                }

                .gallery-item {
                    flex: 0 0 100%;
                    max-width: 100%;

                    @include media-breakpoint-down(sm) {
                        flex: 0 0 33.33333%;
                        max-width: 33.33333%;
                    }
                }
            }

            .gallery-item {
                .gallery-link {
                    border-radius: 10px;
                    height: 100%; 

                    .gallery-img {
                        height: 100%;
                    }
                }
            }
        }
    }
}

.image-collection {
    .gallery {
        .gallery-item {
            &.large-item {
                .gallery-link {
                    border-radius: 16px;
                }
            }
            .gallery-link {
                border-radius: 10px;
            }
        }
    }
}