.card {
    border: none;
    background: none;

    .card-image {
        border-radius: 1.5rem;

        .card-img {
            aspect-ratio: 1;
        }

        .card-image-caption {
            top: 30px;
            bottom: unset;
            right: unset;
            max-width: 10rem;
            background-color: $tertiary;
            border-radius: 0 3px 3px 0;

            .card-image-label {
                padding: 0.875rem 1.5rem;
                line-height: normal;
            }
        }

        &::after {
            content: '\f0a9';
            font-family: $font-awesome;
            font-size: 2.25rem; // 36px
            line-height: 2.25rem; // 36px
            font-weight: 300;
            transform: rotate(45deg);
            color: #F2F6F8;
            position: absolute;
            bottom: 1rem;
            right: 1rem;
        }
    }

    .card-body {
        padding: 1rem 0;

        .card-caption {
            .card-title {
                color: $headings-color;
                font-weight: 400;
            }

            .card-subtitle {
                color: $secondary;
                font-weight: 400;
                font-size: $h5-font-size;
            }

            .card-description {
                .card-description-content {
                    display: -webkit-box;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    -webkit-line-clamp: 4;
                    -webkit-box-orient: vertical;
                }
            }
        }
    }

    .card-tags {
        display: flex;
        flex-flow: row wrap;

        .card-tag {
            padding: .43rem 1rem;
            border: 1px solid $quaternary;
            color: $quaternary;
            border-radius: 50px;
            font-size: 12px;
            line-height: normal;
            margin-bottom: .5rem;
            &:not(:last-child) {
                margin-right: .5rem;
            }
        }
    }
}

// cards in dark section
.bg-dark {
    .card {
        .card-body {
            .card-caption {
                .card-title {
                    color: $white;
                }
            }
        }
    }
}

.page-block {
    &.accommodation-overview {
        .card {
            .card-body {
                .card-caption {
                    flex-grow: 0;
                    .card-description {
                        .card-description-content {
                            -webkit-line-clamp: 10;

                            ul {
                                @extend .custom-list-extra;
                                li {
                                    &::before {
                                        font-weight: 700;
                                        color: $link-color !important;
                                        font-size: 12px;
                                        top: 3px;
                                    }
                                }
                            }
                        }

                        .list-icons {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    &.blog-location-overview {
        .card {
            .card-image {
                .card-img {
                    aspect-ratio: 9/6;
                }
            }

            .card-body {
                .card-caption {
                    flex-grow: 0;
                    .card-description {
                        .card-description-content {
                            -webkit-line-clamp: 7;
                        }
                    }
                }
            }
            .card-buttons {
                .card-btn {
                    background-color: unset;
                    @extend .btn-link;
                    @extend .btn-link-secondary;

                    &:focus,
                    &:active,
                    &:hover {
                        background-color: unset !important;
                        border-color: unset !important;
                    }
                }
            }
        }
    }
}
