&.location-detail {
    .content-section:has(.owner-story) {
        .column.default {
            @include make-row();
            flex-direction: row;

            .thats-why {
                @include make-col-ready();
                margin-bottom: 1rem;

                // @include media-breakpoint-up(sm) {
                //     margin-bottom: 0;
                //     @include make-col(12);
                // }
                
                @include media-breakpoint-up(lg) {
                    @include make-col(8);
                }
            }
            .owner-story {
                @include make-col-ready();

                @include media-breakpoint-up(sm) {
                    @include make-col(6);
                }
                
                @include media-breakpoint-up(lg) {
                    @include make-col(4);
                }

                .card {
                    .card-caption {
                        flex-grow: 0;
                    }
                    .card-body {
                        .card-title {
                            font-size: $h4-font-size;
                        }
                    }
                    .card-buttons {
                        .card-btn {
                            background-color: unset;

                            &::after {
                                transform: none;
                            }
                        }
                    }
                }
            }
        }
    }
}